<template>
  <div id="app"
     :class="$route.name !== 'home' ? 'restoranlar-sayfa' : 'ana-sayfa'"
  >
    <default-header
      v-if="!$route.meta.hideDefaultHeader"
    >
    </default-header>
    <router-view/>
    <app-footer></app-footer>
  </div>
</template>

<script>
import appFooter from '@/components/app-footer'
import DefaultHeader from "@/components/headers/default-header";

export default {
  name: 'App',
  components: {
    DefaultHeader,
    appFooter,
  },
  mounted() {
  }
}
</script>

<style lang="scss">
</style>
