<template>
  <div>
    <div class="location">
      <div class="location__icon">
        <img src="@/assets/img/mark.svg" alt="Location">
      </div>

      <vue-simple-suggest
          :placeholder="this.$route.params.slug || 'Enter your address to find local restaurants'"
          v-model="searchField"
          :list="getAutocompleteList"
          @suggestion-click="searchRestaurants"
          :min-length="minLength"
          :max-suggestions="maxSuggestions"
          :debounce="debounce"
      />
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS

export default {
  name: "search-restaurants",
  components: {
    VueSimpleSuggest,
  },
  data() {
    return {
      minLength: 3,
      maxSuggestions: 6,
      debounce: 200,
      searchField: '',
    }
  },
  methods: {
    async getAutocompleteList() {
      return fetch(`${process.env.VUE_APP_API_URL}/autocomplete?q=${this.searchField}`, {method: 'GET'})
          .then(response => response.json())
          .then(json => json.results);
    },
    async searchRestaurants(suggestion) {
      if (this.$route.params.slug !== suggestion) {
        await this.$router.push({path: `/restaurants/${suggestion}`})
      }
    },
  },
}
</script>
<style lang="scss">
.location {
  position: relative;

  &.__open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .location-dropdown {
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: white;
    padding: 10px;
    border-top: 1px solid #ccc;
    border-radius: 0 0 10px 10px;
    z-index: 1000;
    max-height: 400px;
    overflow-y: auto;
    display: none;

    li {
      padding: 2px 0;

      button {
        background-color: transparent;
        padding: 0;
        border: none;
        cursor: pointer;
        text-decoration: none;
        font-size: 16px;
        color: black;
        transition: .3s ease all;

        &:hover {
          opacity: .8;
        }
      }
    }

    &.__active {
      display: block;
    }
  }
}

.vue-simple-suggest {
  &.designed {
    width: 100%;
  }
}

.default-input {
  border:0 !important;
  border-radius: 0 !important;
  background: none !important;
}
</style>