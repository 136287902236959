<template>
  <footer id="footer" class="footer">
    <div class="footer__main">
      <div class="kolon footer__sol">
        <router-link to="/" class="footer__logo">
          <img src="@/assets/img/brand-logo.svg" alt="Meal Dealer Brand Logo">
        </router-link>
        <div class="footer__butonlar">
          <a href="#" class="download-buton buton buton--hayalet">
            <img src="@/assets/img/google-play.svg" alt="Download from google play buton">
          </a>
          <a href="#" class="download-buton buton buton--hayalet">
            <img src="@/assets/img/app-sotre.svg" alt="Download from app store buton">
          </a>
        </div>
      </div>
      <div class="kolon footer__sag">
        <nav class="footer__nav">
          <div
            class="kolon"
            v-for="nav in footerNav"
            :key="nav.id"
          >
            <router-link
              class="buton buton--link"
              v-for="navLink in nav.footerNavLinks"
              :key="navLink.title"
              :to="navLink.href"
            >
              {{ navLink.title }}
            </router-link>
          </div>
        </nav>
      </div>
    </div>
    <div class="footer__sub">
      <div class="footer__sosyal">
        <router-link
          v-for="socialLink in footerSocial"
          :key="socialLink.id"
          :to="socialLink.href"
          class="buton buton--"
        >
          <img
            :src="socialLink.img"
            :alt="socialLink.alt"
          >
        </router-link>
      </div>
      <nav class="footer__sub-nav">
        <router-link
          to="privacy-policy"
          class="buton buton--link"
        >
          Privacy policy
        </router-link>
        <router-link
          to="#"
          class="buton buton--link"
        >
          Cookies
        </router-link>
        <router-link
          to="#"
          class="buton buton--link"
        >
          Terms and conditions
        </router-link>
      </nav>
    </div>
    <div class="footer__cc">
      <p>© 2022 MealDealer</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
  data() {
    return {
      footerNav: [
        {
          id: 1,
          footerNavLinks: [
            {
              title: 'about us',
              href: '#'
            },
            {
              title: 'address',
              href: '#'
            },
            {
              title: 'press',
              href: '#'
            }
          ]
        },
        {
          id: 2,
          footerNavLinks: [
            {
              title: 'careers',
              href: '#'
            },
            {
              title: 'investors',
              href: '#'
            },
            {
              title: 'partnerships',
              href: '#'
            },
            {
              title: 'Add your restaurant',
              href: '#'
            }
          ]
        }
      ],
      footerSocial: [
        {
          id: 1,
          href: '#',
          img: require('@/assets/img/instagram.svg'),
          alt: ''
        },
        {
          id: 2,
          href: '#',
          img: require('@/assets/img/facebook.svg'),
          alt: ''
        },
        {
          id: 3,
          href: '#',
          img: require('@/assets/img/twitter.svg'),
          alt: ''
        },
        {
          id: 4,
          href: '#',
          img: require('@/assets/img/linkedin.svg'),
          alt: ''
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>