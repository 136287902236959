<template>
  <header class="header">
    <section class="header__icerik">
      <div class="satir header__main">
        <router-link to="/" class="header__logo buton buton--hayalet">
          <img src="@/assets/img/brand-logo.svg" alt="MealDealer Logo">
          <h5>MealDealer</h5>
        </router-link>
        <div class="switcher header__switcher">
          <input type="checkbox" name="delivery-type" id="delivery-type" kapali>
          <label for="delivery-type">delivery</label>
          <label for="delivery-type">pickup</label>
          <div class="switcher__hover"></div>
        </div>
      </div>
      <div class="satir header__sub">
        <div class="food-types">
          <router-link
              to="#"
              class="food-types__food-type buton buton--hayalet"
              v-for="(foodType,idx) in foodTypes"
              :key="idx"
          >
            <img
                :src="foodType.img"
                :alt="foodType.alt"
            >
            <span>{{ foodType.title }}</span>
          </router-link>
        </div>
        <div class="v-select__inner">
          <v-select
              class="select-lang"
              :options="selectOptions"
              :clearable="false"
              :reduce="(option) => option.id"
              :searchable="false"
              v-model="selected"
          ></v-select>
        </div>
      </div>
    </section>
    <section class="hero header__hero">
      <div class="kolon">
        <h1>The largest food <br> & groceries offer in town</h1>
        <searchRestaurants/>
        <div class="hero__slogan">
          <h5>
            Compare prices and delivery times <br>
            —> always get the best deal
          </h5>
          <img class="hero__slogan-gorsel" src="@/assets/img/forground-assets.png" alt="Hero Forground Asset">
        </div>
      </div>
      <div class="kolon hero__gorseller">
        <img class="hero__gorsel" src="@/assets/svg/pizza.svg" alt="Pizza">
      </div>
    </section>
  </header>
</template>

<script>
import searchRestaurants from '@/components/search-restaurants/search-restaurants'

export default {
  name: "app-header",
  components: {
    searchRestaurants
  },
  data() {
    return {
      foodTypes: [
        /*{
          img: require('@/assets/img/fast-food.svg'),
          alt: 'Fast Food Icon',
          title: 'Fast Food'
        },
        {
          img: require('@/assets/img/pizza.svg'),
          alt: 'Pizza Food Icon',
          title: 'pizza'
        },
        {
          img: require('@/assets/img/asian.svg'),
          alt: 'Asian Food Icon',
          title: 'asian'
        },
        {
          img: require('@/assets/img/grocery.svg'),
          alt: 'Grocery Food Icon',
          title: 'grocery'
        },
        {
          img: require('@/assets/img/healthy.svg'),
          alt: 'Healthy Food Icon',
          title: 'healthy'
        }*/
      ],
      selected: 1,
      selectOptions: [
        {
          label: 'NL',
          id: 1
        },
        {
          label: 'EN',
          id: 2
        },
        {
          label: 'DE',
          id: 3
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.v-select__inner {
  @media screen and (max-width: 992px) {
    position: absolute;
    top: 2rem;
    right: 1rem;
  }
}

.select-lang .vs__dropdown-menu {
  @media screen and (max-width: 992px) {
    left: auto;
    right: 0;
  }
}

.select-lang .vs__dropdown-toggle {
  border: none;
}

.select-lang .vs__search {
  display: none;
}

.select-lang .vs--single.vs--loading .vs__selected, .vs--single.vs--open .vs__selected {
  opacity: 1 !important;
  position: static !important;
  font-size: 14px !important;
}

.select-lang .vs__dropdown-menu {
  padding: 0;
  border-radius: 5px;
}

.select-lang .vs__dropdown-option {
  font-size: 14px;
}

.select-lang .vs__dropdown-option--selected {
  font-weight: 700;
}

.select-lang .vs__selected {
  font-size: 14px !important;
}

.select-lang .vs__open-indicator {
  fill: #999;
}

:root {
  --vs-font-size: 25px !important;
  --vs-dropdown-option--active-bg: #f6f6f6 !important;
  --vs-dropdown-option--active-color: #000000 !important;
  --vs-dropdown-min-width: 75px !important;
  --vs-dropdown-max-height: 150px !important;
  --vs-border-radius: 5px !important;
  --vs-dropdown-option-padding: 9px 9px 9px 18px !important;
  --vs-controls-size: 0.5 !important;
  --vs-controls-color: red !important;
  --vs-controls-deselect-text-shadow: none !important;
}

.location {
  position: relative;

  &.__open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .location-dropdown {
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: white;
    padding: 10px;
    border-top: 1px solid #ccc;
    border-radius: 0 0 10px 10px;
    z-index: 1000;
    max-height: 400px;
    overflow-y: auto;
    display: none;

    li {
      padding: 2px 0;

      button {
        background-color: transparent;
        padding: 0;
        border: none;
        cursor: pointer;
        text-decoration: none;
        font-size: 16px;
        color: black;
        transition: .3s ease all;

        &:hover {
          opacity: .8;
        }
      }
    }

    &.__active {
      display: block;
    }
  }
}
</style>