<template>
  <!-- Header -->
  <header id="header" class="header">
    <section class="header__icerik">
      <div class="satir header__main">
        <router-link to="/" class="header__logo buton buton--hayalet">
          <img src="@/assets/img/brand-logo.svg" alt="MealDealer Logo">
          <h5>MealDealer</h5>
        </router-link>
        <div class="switcher header__switcher">
          <input type="checkbox" name="delivery-type" id="delivery-type" kapali>
          <label for="delivery-type">delivery</label>
          <label for="delivery-type">pickup</label>
          <div class="switcher__hover"></div>
        </div>
      </div>
      <search-restaurants class="satir header__location"/>
      <div class="satir header__food-types">
        <div class="food-types">
          <div
            class="food-types__food-type buton buton--hayalet"
            v-for="(foodType,idx) in foodTypes"
            :key="idx"
            @click="filteredProduct(foodType)"
          >
            <img
              :src="foodType.img"
              :alt="foodType.alt"
            >
            <span>{{ foodType.title }}</span>
          </div>
        </div>
      </div>
    </section>

  </header>
</template>

<script>
import searchRestaurants from '@/components/search-restaurants/search-restaurants'
import {eventBus} from "@/main";

export default {
  name: "default-header",
  components: {
    searchRestaurants
  },
  data() {
    return {
      foodTypes: [
        {
          img: require('@/assets/img/fast-food.svg'),
          alt: 'Fastfood',
          title: 'Fastfood',
          value: 'fastfood'
        },
        {
          img: require('@/assets/img/pizza.svg'),
          alt: 'Pizza',
          title: 'pizza',
          value: 'pizza'
        },
        {
          img: require('@/assets/img/asian.svg'),
          alt: 'Asian',
          title: 'asian',
          value: 'asian'
        },
        {
          img: require('@/assets/img/grocery.svg'),
          alt: 'Groceries',
          title: 'grocery'
        },
        {
          img: require('@/assets/img/healthy.svg'),
          alt: 'Healthy',
          title: 'healthy',
          value: 'healthy'
        }
      ],
      radioBtnRangeFilter: [],
    }
  },
  methods: {
    filteredProduct(item) {
      if(item.value) {
        this.radioBtnRangeFilter = item.value
        eventBus.$emit('value', this.radioBtnRangeFilter)
      }
    }
  }
}
</script>

<style scoped>

</style>