<template>
  <div
    role="button"
    class="restoran-kart"
  >
    <div
      class="restoran-kart__banner lazy-load__inner"
      v-lazyLoad
    >
      <img
        alt="name"
        :data-url="product.logo"
        loading="lazy"
      >
    </div>
    <div class="restoran-kart__icerik">
      <div class="restoran-kart__baslik">
<!--        <div-->
<!--          class="restoran-kart__logo logo lazy-load__inner"-->
<!--          v-lazyLoad-->
<!--        >-->
<!--          <img-->
<!--            :data-url="product.logo"-->
<!--            alt="name"-->
<!--            loading="lazy"-->
<!--          >-->
<!--        </div>-->
        <div class="restoran-kart__bilgiler">
          <div class="restoran-kart__isim">
            <h5>
              {{ product.restaurants[0].name }}
            </h5>
          </div>
          <div class="restoran-kart__detaylar">
            <div class="restoran-kart__detay restoran-kart__puan">
              <div class="stars__inner">
                <div class="stars" :style="{'--rating': product.restaurants[0].rating}">
                </div>
              </div>
              <span>
                {{ product.restaurants[0].rating | ratingNum }}
              </span>
            </div>
            <div class="restoran-kart__detay restoran-kart__puan">
              <span>{{ product.distance }} km</span>
              <img
                src="@/assets/img/motor.png"
                alt="motor png image"
              >
            </div>
            <div class="restoran-kart__detay restoran-kart__puan">
              <div class="radio radio--2">
                <label class="radio__label">
                  <span class="radio__label-yazi">
                    {{ product.priceRange }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="restoran-kart__sureler">
        <div
          class="restoran-kart__tts tts"
          v-for="(restaurants,idx) in product.restaurants"
          :key="restaurants.identifier"
          :class="[idx !== 0 ? 'tts--sub default__border' : '', product.restaurants.length === 2 && idx === 1 && idx !== 0 ? '__location-el' : '']"
          @click="redirectRoute(restaurants)"
        >
          <div
            class="tts__icerik"
            v-if="idx === 0"
          >
            <div class="tts__baslik">
              <h6>
                <!--<span :v-if="idx === 0" class="badge">Our recommended deal</span>-->
                {{ restaurants.platform }}
              </h6>
            </div>
            <div class="tts__top">
              <img class="time-svg" src="@/assets/img/time.svg" alt="time svg image">
              <span>{{ restaurants.estimatedDeliveryTime }} min</span>
            </div>
            <div class="tts__bottom">
              <div class="tts__detaylar">
                <span>Delivery fee</span>
                <h5>€ {{ restaurants.deliveryFee / 100}}</h5>
              </div>
              <div class="order-buton buton">
                <span>order now</span>
                <img src="@/assets/img/right-arrow.svg" alt="right arrow">
              </div>
            </div>
          </div>
          <div
            v-else
            class="tts__icerik"
          >
            <div class="kolon">
              <div class="tts__baslik">
                <h6>{{ restaurants.platform }}</h6>
              </div>
              <div class="tts__top">
                <img class="time-svg" src="@/assets/img/time.svg" alt="time">
                <span>{{ restaurants.estimatedDeliveryTime }} min</span>
              </div>
            </div>
            <div class="kolon">
              <div class="tts__bottom">
                <div class="tts__detaylar">
                  <span>Delivery fee</span>
                  <h6>€ {{ restaurants.deliveryFee / 100 }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LazyLoadDirective from "../directives/LazyLoadDirective";

export default {
  name: "product",
  directives: {
    lazyLoad: LazyLoadDirective
  },
  props: {
    product: {
      type: Object,
      default: (() => {})
    },
  },
  methods: {
    async redirectRoute(currentDeliveryService) {
      await this.$http.get(`${process.env.VUE_APP_API_URL}${currentDeliveryService.link}`)
      .then( response => {
        window.open(response.data.url, '_blank')
      })
      .catch(err => {
        console.log(err, 'error')
      })
    }
  },
  filters: {
    ratingNum(val) {
      if(val.toString().length > 3) {
        return val.toFixed(2)
      } else {
        return val
      }
    }
  },
}
</script>

<style lang="scss">
.logo {
  max-width: 74px;
  max-height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
    border-radius: 10px;
  }
}
.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: 16px;
  font-family: "Poppins", sans-serif;

  &::before {
    content: '★★★★★';
    letter-spacing: 4px;
    background: linear-gradient(90deg, rgba(100, 100, 100, .7) var(--percent), white var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #adadad
  }
}
.restoran-kartlar {
  width: 100% !important;
  //flex-direction: column !important;
}

.restoran-kart__banner {
  max-height: 10rem;
  width: auto;
}

.restoran-kart__icerik {
  width: auto;
}

.filtre-form {
  @media screen and (max-width: 1210px) {
    width: 100% !important;
  }
}

.restoranlar {
  @media screen and (max-width: 1210px) {
    flex-direction: column !important;
  }
}

.tts__bottom {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.restoran-kartlar .restoran-kart {
  flex-direction: column !important;
  cursor: pointer;
  //flex: 50%;
  //max-width: 767.5px;
  @media screen and (max-width: 992px) {
    max-width: 100%;
  }
  //@media (min-width: 1201px) and (max-width: 1210px) {
  //  flex-basis: calc(50% - 0.9rem) !important;
  //}
}

.tts--sub + .tts--sub {
  border-top: none;
}

.tts--sub {
  .tts__icerik {
    align-items: flex-start;
  }
}

.default__border {
  border-bottom: 1px solid #d8d8d8 !important;
}

.default__border + .default__border {
  border-bottom: none !important;
}

.lazy-load__inner {
  img {
    opacity: 0;
    transition: .3s ease all;
  }
  &.loaded {
    img {
      opacity: 1;
    }
  }
}

.__location-el {
  grid-row-end: -1 !important;
  border-bottom: none !important;

  .tts__icerik {
    flex-direction: column;

    .tts__bottom {
      padding-top: 13px;
    }

    .tts__detaylar {
      font-size: .7rem;
      span {
        display: inline-block;
        height: 17px;
      }
    }
  }
}

span.badge {
  font-size: 0.75rem;
  background: white;
  padding: 4px 10px;
  border-radius: 15px;
}
</style>