import './assets/style/main.scss'
import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import router from '../src/router/index'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Axios from 'axios'
Vue.config.productionTip = false

export const eventBus = new Vue()
Vue.use(VueRouter)
Vue.component('v-select', vSelect)
Vue.prototype.$http = Axios;

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
