import VueRouter from "vue-router";

import Home from '@/pages/Home'
import Restaurants from '@/pages/Restaurants'
import PrivacyPolicy from "@/pages/PrivacyPolicy";

export default new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        hideDefaultHeader: true,
      }
    },
    {
      path: '/restaurants/:slug',
      name: 'restaurants',
      component: Restaurants,
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy,
    }
  ],
  mode: 'history'
})