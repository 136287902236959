<template>
  <main id="root">
<!--    <slider></slider>-->
    <section id="restoranlar" class="restoranlar">
      <div class="filtre-form reduced-motion">
        <form action="" name="restoran-filtre" class="restoran-filtre dropdown-set dropdown-set__sticky">
          <div class="dropdown-set__icerik restoran-filtre__icerik">
            <fieldset class="dropdown aktif dropdown-set__eleman">
              <div
                class="dropdown__buton"
                @click="tempClick"
              >
                <h6 class="dropdown__baslik">Cuisines</h6>
                <img
                  class="dropdown-arrow" src="@/assets/img/filter-arrow-icon.svg"
                  :class="tempActive ? '__is-active' : ''"
                  alt=""
                >
              </div>
              <div
                class="dropdown__icerik"
                :class="tempActive ? '__is-active' : ''"
              >
                <div
                  class="radio"
                  v-for="(filterBtn,idx) in filtersBtns"
                  :key="idx"
                >
                  <input
                    type="radio"
                    name="rad"
                    :id="filterBtn.title + '-' + idx"
                    :value="filterBtn.value"
                    v-model="filter"
                    @change="getRestaurants"
                  >
                  <label
                    :for="filterBtn.title + '-' + idx"
                    class="radio__label"
                  >
                    <div class="radio__label-kutu"></div>
                    <span class="radio__label-yazi">
											{{ filterBtn.title }}
										</span>
                  </label>
                </div>
              </div>
            </fieldset>
            <fieldset class="dropdown aktif dropdown-set__eleman restoran-filtre__value">
              <div
                class="dropdown__buton"
                @click="toggleCurrencyFilter"
              >
                <h6 class="dropdown__baslik">Price Range</h6>
                <img
                  class="dropdown-arrow" src="@/assets/img/filter-arrow-icon.svg"
                  :class="isActive ? '__is-active' : ''"
                  alt=""
                >
              </div>
              <div
                class="dropdown__icerik"
                :class="isActive ? '__is-active' : ''"
              >
                <div
                  class="radio radio--2"
                  v-for="(range,idx) in sortByRange"
                  :key="idx"
                >
                  <input
                    type="radio"
                    name="value"
                    :value="range.value"
                    :id="range.title + '-' + idx"
                    v-model="radioBtnRangeFilter"
                  >
                  <label
                    :for="range.title + '-' + idx"
                    class="radio__label"
                  >
										<span class="radio__label-yazi">
											{{ range.title }}
										</span>
                  </label>
                </div>
              </div>
            </fieldset>
            <!--            /////temporary static//-->
          </div>
        </form>
      </div>
          <Overlay
              :opened="loadingPopupOpened"
              :visible="loadingPopupVisible"
              @closed="loadingPopupOpened = loadingPopupVisible = false"
              overlay="zoom-in"
              :closeable="popupClosable"
          >
            <div class="popup">
              <strong>comparing all platforms... Looking for the best food deal</strong>
            </div>
          </Overlay>
      <div class="restoran-kartlar">
          <product
            v-for="restaurants in filteredProductsRes"
            :key="restaurants.restaurants[0].identifier"
            :product="restaurants"
          ></product>
      </div>
    </section>
  </main>
</template>

<script>
//import slider from '@/components/slider/slider'
import product from '@/components/product'
import {Overlay} from 'vuejs-overlay'
import {eventBus} from "@/main";

export default {
  name: "Restaurants",
  components: {
    //slider,
    product,
    Overlay,
  },
  data() {
    return {
      loadingPopupVisible: false,
      loadingPopupOpened: false,
      popupClosable: false,

      sortByRange: [
        {
          title: '€',
          value: '€'
        },
        {
          title: '€€',
          value: '€€'
        },
        {
          title: '€€€',
          value: '€€€'
        },
        {
          title: '€€€€',
          value: '€€€€'
        },
      ],
      productArr: [],
      isActive: true,
      tempActive: true,
      filtersArr: [],
      filter: [],
      radioBtnRangeFilter: [],
      filtersBtns: [
        {
          title: 'Fastfood',
          value: 'fastfood'
        },
        {
          title: 'pizza',
          value: 'pizza'
        },
        {
          title: 'Asian',
          value: 'asian'
        },
        {
          title: 'Healthy',
          value: 'healthy'
        }
      ],
    }
  },
  created() {
    eventBus.$on('value', data => {
      this.filter = data
      this.getRestaurants()
    })
  },
  watch: {
    '$route'(newVal, oldVal) {
      if(newVal.params.slug !== oldVal.params.slug) {
        this.filter = []
        this.radioBtnRangeFilter = []
        this.getRestaurants()
      }
    }
  },
  computed: {
    filteredProductsRes() {
      const positionFilter = this.filter
      const positionRangeFilter = this.radioBtnRangeFilter
      if (positionFilter.length && positionRangeFilter.length) {
        return this.productArr && this.productArr.filter(item => positionRangeFilter === item.priceRange)
      } else if (positionFilter.length) {
        return this.productArr
      } else if (positionRangeFilter.length) {
        return this.productArr.filter(el => {
          return positionRangeFilter === el.priceRange
        })
      }
      return this.productArr
    },
  },
  methods: {
    toggleCurrencyFilter() {
      this.isActive = !this.isActive
    },
    tempClick() {
      this.tempActive = !this.tempActive
    },
    updatePageTitle(slug) {
      document.title = `MealDealer - Restaurants near ${decodeURIComponent(slug)}`
    },
    openLoadingPopup: function () {
      this.loadingPopupVisible = true;
      this.loadingPopupOpened = true;
      this.popupClosable = true;
    },
    closeLoadingPopup: function () {
      this.loadingPopupVisible = false;
      this.loadingPopupOpened = false;
      this.popupClosable = true;
    },
    async getRestaurants() {
      this.updatePageTitle(this.$route.params.slug);
      this.openLoadingPopup();
      await this.$http.post(`${process.env.VUE_APP_API_URL}/aggregate?property=estimatedDeliveryTime&direction=asc`, {
        "address": this.$route.params.slug,
        "cuisine": this.filter.length !== 0 ? this.filter : ''
      }).then( res => {
        this.productArr = res.data
        this.modifyProductArr(res.data);
      }).catch(err => {
        console.log(err, 'error')
      });
      this.closeLoadingPopup();
    },
    modifyProductArr(/*products*/) {
      // const tempArr = [...products]
      // console.log(tempArr, 'products')
    }
  },
  mounted() {
    this.updatePageTitle(this.$route.params.slug);
    this.getRestaurants()
  }
}
</script>

<style lang="scss" scoped>
.dropdown-arrow {
  transform: rotate(-180deg);

  &.__is-active {
    transform: rotate(0)
  }
}

.dropdown__icerik {
  max-height: 0;

  &.__is-active {
    max-height: 100%;
  }
}

.popup {
  text-align: center;
  padding-top:40px;
  padding-bottom:40px;
}
</style>