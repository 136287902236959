<template>
  <main>
    <section>
      <div class="content">
        <h1>Privacy Policy</h1>
        <p>You know us as Mealdealer, but our legal name is Mealdealer Ltd
          ("we", "our", "us" or "Mealdealer"). We are committed to protecting the privacy of
          all users of our website mealdealer.co.uk, or mobile applications (together,
          the "Sites"). Please read this privacy policy that explains how we use and protect
          your personal information. We are the "controller" of the information we process,
          unless otherwise stated.
        </p>
        <h3>1. Contact Details</h3>
        <p>
          If you have any queries or requests about this privacy policy or how we handle your information more
          generally, you can get in touch by contacting us at support@mealdealer.co.uk
        </p>
        <h3>2. How We Collect Your Information</h3>
        <p>
          We collect your information when you interact with us or use our services, such as when you use our Sites to
          place an order. We also look at how visitors use our Sites, to help us improve our services and optimise
          customer experience.<br>
          We collect information:<br>
        </p>
        <ol type="1">
          <li>when you create an account with us or you change your account settings;</li>
          <li>when you place an order with us and during the order process (including for payment and order delivery);
          </li>
          <li>when you create an account with our partners after having been referred to those partners through the
            Mealdealer
            Sites;
          </li>
          <li>when you place an order with our partners and during the order process (including for payment and order
            delivery) after having been referred to those partners through the Mealdealer Sites;
          </li>
          <li>through your interactions with us or our services, such as when you request information or to receive
            marketing,
            information about Mealdealer initiatives or other communications from us by email, phone, post, SMS, push
            notification, or via our chat function;
          </li>
          <li>when you participate in a competition, prize draw, promotion about our services, or our partners’
            services; and
            when you browse or use our Sites (before and after you create an account with us).
          </li>
        </ol>
        <p>We also collect information from third party sites, such as advertising and social media platforms and our
          fraud detection provider. If you link your social media or your third-party accounts to us, we will keep a
          record of your social media handle, and the other information that is made available to us according to your
          social media account settings.</p>
        <h3>3. Information We Collect From You</h3>
        <p>
          As part of our commitment to the privacy of our customers and visitors to our Sites more generally, we want to
          be clear about the sorts of information we will collect from you.
          When you visit the Sites or make an order initiated through the Mealdealer Sites, including any partner’s
          website we work with to provide delivery services, you are asked for information about yourself including your
          name, contact details, delivery address, order details, loyalty scheme details where applicable, and payment
          information such as credit or debit card details. We will also collect information from you when you contact
          our riders using the chat function on our Sites. We will also collect your date of birth to verify your age
          when you purchase age restricted items.
          We collect information about your use of the Sites and information about you from any messages you post to the
          Sites or when you contact us or provide us with feedback, including via email, post, phone or chat function.
          If you contact us by phone, we record and make notes about the call, including for training and service
          improvement purposes.
          We collect information from your mobile device or computer, such as its operating system, the device and
          connection type and the IP address from which you are accessing our Sites. We also collect technical
          information about your use of our services through a mobile device, for example, carrier, location data and
          performance data such as mobile payment methods, interaction with other retail technology such as use of NFC
          Tags, QR Codes and/or use of mobile vouchers. Unless you have elected to remain anonymous through your device
          and/or platform settings, this information may be collected and used by us automatically if you use the
          service through your mobile device(s) via any MealDealer mobile application, through your mobile's browser or
          otherwise.
          We process health information about you only where you volunteer and consent to this, for example if you
          report any specific food allergies after placing an order.
          Where we need to collect information by law, or under the terms of a contract we have with you, and you fail
          to provide that information, we may not be able to perform the contract we have or are trying to enter into
          with you (for example, to provide you with our services). In this case, we may have to cancel our service to
          you but we will notify you if this is the case at the time.
        </p>
        <h3>4. Use Of Your Information</h3>
        <p>
          We will only process your information if there is a reason for doing so, and if that reason is permitted by
          law.
          Where we need to provide you with the service you have requested or to enter into a contract with you, we use
          your information to:
        </p>
        <ul>
          <li>enable us to provide you with access to the relevant parts of the Sites, we collect necessary cookies from
            your device;
          </li>
          <li>supply the services you have requested, we collect your name, contact details, delivery address and order
            details;
          </li>
          <li>enable us to collect payment from you, we collect your credit or debit card information; and</li>
          <li>contact you where necessary concerning our services, such as to resolve issues you may have with your
            order, we collect the information listed above and any additional information we may need to resolve your
            issue.
          </li>
        </ul>
        <p>We also process your information where we have a legitimate interest for doing so, which are to:</p>
        <ul>
          <li>personalise our services, including to make it easier and faster for you to place orders;</li>
          <li>improve the effectiveness and quality of service that our customers can expect from us in the future;</li>
          <li>tailor content that we or our partners display to you, for example so that we can show you partners which
            are in your area or make sure you see the advertising which is most relevant to you, based on
            characteristics determined by us;
          </li>
          <li>enable our customer support team to help you with any enquiries or complaints in the most efficient way
            possible and to provide a positive customer experience;
          </li>
          <li>contact you for your views and feedback on our services or our partners’ services and/or products and to
            notify you if there are any important changes or developments to the Sites or our services, including
            letting you know that our services are operating in a new area, where you have asked us to do so;
          </li>
          <li>send you information by post about our products, services, promotions and Deliveroo initiatives (if you do
            not want to receive these, you can let us know by getting in touch (Please see section <strong>1. Contact
              Us</strong>));
          </li>
          <li>analyse your activity on the Sites so that we can administer, support, improve and develop our business
            and for statistical and analytical purposes and to help us to prevent fraud; and
          </li>
          <li>detect, investigate, report and seek to prevent fraud or crime.</li>
        </ul>
        <p>We also process your information to enforce our contractual terms with you and any other agreement, to ensure
          compliance with our internal policies and procedures and for the exercise or defence of legal claims and to
          protect the rights of Mealdealer, our partners, or others (including to prevent fraud).</p>
        <p>
          If you submit comments and feedback regarding the Sites and the services, we may use such comments and
          feedback on the Sites and in any marketing or advertising materials. We will only identify you for this
          purpose by the name and the city you have provided us and such comments and feedback may be shared with our
          partners to assess and improve their services.
        </p>
        <p>
          We will also analyse data about your use of the Sites and the services to create profiles relating to you and
          for you. This means that we may make certain assumptions about what you may be interested in and use this, for
          example, to send you more tailored marketing communications, to present you with partners that we think you
          will prefer, or to let you know about special offers or products which we think you may be interested in
          (including Deliveroo for Work). This activity is referred to as profiling. You have certain rights in relation
          to this type of processing. Please see section <strong>12. Your Rights</strong> for more details.
          We may also use your information to comply with any legal obligation or regulatory requirement to which we are
          subject.
        </p>
        <h3>5. Cookies</h3>
        <p>
          You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access
          cookies. If you disable or refuse cookies, please note that some parts of the Site may become inaccessible or
          not function properly. For more information about the cookies we use, and how to set or amend your cookie
          preferences, please see our Cookie Policy.
        </p>
        <h3>6. Marketing</h3>
        <p>
          Where you have given your consent or where we have a legitimate interest for doing so (and are permitted to do
          so by law) we will use your information to let you know about our other products and services, or MealDealer
          initiatives that may be of interest to you and we may contact you to do so by email, post, phone, or push
          notification or in-app message.
        </p>
        <p>
          We use online advertising to keep you aware of what we’re up to and to help you see and find our products.
        </p>
        <p>
          You may see MealDealer banners and ads when you are on other websites and apps, such as on social media. We
          manage this through a variety of digital marketing networks and using a range of advertising technologies. The
          banners and ads you see are based on information we hold about you, or your previous use of our services (for
          example, your order history) or on banners or ads on our Sites that you have previously clicked on. We use
          your information to send you communications that are the most relevant to you. You have certain rights in
          relation to this type of processing. Please see section 12. Your Rights for more details.
          For more information on our use of advertising technologies and cookies, please see our Cookie Policy.
        </p>
        <p>
          You can control your marketing preferences by:</p>
        <ul>
          <li>visiting our website, <a href="https://mealdealer.co.uk">www.mealdealer.co.uk</a>, or mobile application;
          </li>
          <li>clicking on "Account"; and</li>
          <li>scrolling down to "Marketing Preferences".</li>
        </ul>
        <p>Where you have chosen at a device level to begin or continue receiving push notifications from us, we may
          send you push notifications relating to the services that you have requested from us and information about our
          services, offers and MealDealer initiatives. You can choose to stop receiving push notifications from us at
          any time by changing your preferences on your mobile device.</p>
        <p>We may still contact you through email where you have opted out of direct marketing with service
          communications, including, but not limited to, correspondence providing information about your order, service
          interruption and delivery safety.</p>
        <h3>7. Automated Decisions</h3>
        <p>We undertake fraud checks on all customers which is necessary for us to:</p>
        <ul>
          <li>perform our contracted services to customers;</li>
          <li>ensure that our services (and those of all our partners) provided are duly paid for;</li>
          <li>ensure that customers themselves are protected from fraudulent transactions being made on their payment
            cards.
          </li>
        </ul>
        <p>
          Given the volume of customer orders we handle or refer, we use automated systems. These systems analyse order
          data to make automated decisions as to whether or not we should accept an order. This is a fairer and more
          accurate and efficient way of conducting fraud checks since human checks would simply not be possible in the
          timeframes and given the volumes of customers that we deal with.
        </p>
        <p>
          The checks and decisions that are made look at various components including known industry indicators of fraud
          which our expert fraud detection provider makes available to us, as well as fraud patterns we have detected on
          our Sites. When combined, these generate an automated score indicating the likelihood of a fraudulent
          transaction. Where we believe there may be fraudulent activity we may block you from placing an order and
          using our Sites. The specific fraud indicators are dynamic so will change depending on what types of fraud are
          being detected in the industry, country and our Sites at any particular time.
        </p>
        <p>Our fraud detection is in place to protect our customers, as well as Deliveroo. You have the right to contest
          any automated decision made about you and to be given more information about why any such decision was made.
          Please see section <strong>12. Your Rights</strong> for more details.</p>
        <h3>8. Retention Of Your Information</h3>
        <p>We will only retain your information for as long as reasonably necessary to fulfil the purposes we collected
          it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting
          requirements. We may retain your information for a longer period in the event of a complaint or if we
          reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
        <p>When determining the appropriate retention periods, we will take into account factors including:</p>
        <ul>
          <li>our contractual obligations and rights in relation to the information involved;
          </li>
          <li>legal obligation(s) under applicable law to retain information for a certain period of time;</li>
          <li>statute of limitations under applicable law(s);</li>
          <li>our legitimate interests for retaining the information (please see section <strong>4. Use of Your
            Information</strong>);
          </li>
          <li>whether there is an actual or potential dispute; and</li>
          <li>guidelines issued by relevant data protection authorities.</li>
        </ul>
        <p>Otherwise, we securely erase your information where we no longer require it for the purposes we collected it
          for.</p>

        <h3>9. Sharing Your Information</h3>
        <p>The information we collect about you will be transferred to and stored on our servers located within the EU.
          We are very careful and transparent about who else your information is shared with.</p>
        <p>We share your information with other MealDealer group companies or partners only where necessary for the
          purposes set out in the Use of Your Information section above. We share your information with our partners
          (such as third party delivery platforms, restaurants, grocers, and other retail partners), who have access to
          limited information to enable them to fulfil an order. Where relevant and you give your consent, we may share
          the health information you volunteer to us with partners to enable them to investigate and respond to
          complaints.</p>
        <p>We share your information with third party service providers that provide services on our behalf. The types
          of third party service providers whom we share your information with include for example:</p>
        <ul>
          <li>Payment providers (including online payment providers and fraud detection providers);</li>
          <li>IT service providers (including cloud providers);</li>
          <li>Riders, who have access to limited information to enable them to fulfil an order;</li>
          <li>Customer support providers (including, but not limited to, companies that assist us to provide customer or
            technical support); and
          </li>
          <li>Marketing and advertising partners.</li>
        </ul>
        <p>We share your information when we promote a programme or offer a service or product in conjunction with a
          third-party business partner. We will share your information with that partner to assist in marketing or to
          provide the associated product or service. In most of those cases, the programme or offer will include the
          name of the third-party business partner, either alone or with ours. An example of such a business partner
          relationship would be a partner that we partner with for providing delivery services.</p>
        <p>
          If you submit comments and feedback regarding the Sites, services, and our partners we may share such comments
          and feedback with our partners. In addition, and if you consent to it, we may share health information about
          you with our partners, for example if you report any specific food allergies after placing an order.
        </p>
        <p>Deliveroo will take all steps reasonably necessary to ensure that your data is treated securely and in
          accordance with this privacy policy and applicable laws when it is transferred to third parties.
        </p>
        <p>If our business enters into a joint venture with, purchases or is sold to or merged with another business
          entity, your information may be disclosed or transferred to the target company, our new business partners or
          owners or their advisors.</p>
        <p>We may also share your information:</p>
        <ul>
          <li>if we are under a duty to disclose or share your information in order to comply with (and/or where we
            believe we are under a duty to comply with) any legal obligation or regulatory requirement;
          </li>
          <li>in order to enforce our contractual terms with you and any other agreement;</li>
          <li>to protect the rights of Deliveroo, our partners, riders, or others, including to prevent fraud; and</li>
          <li>
            with such third parties as we reasonably consider necessary in order to prevent crime, e.g. the police or
            for health and safety purposes.
          </li>
        </ul>
        <p>In some cases the information we collect from you might be processed outside the United Kingdom or the
          European Economic Area, such as the United States and the countries in which MealDealer operates. These
          countries may not have the same protections for your information as the UK or EEA has. To the extent these
          countries have not been lawfully recognised as providing an adequate level of data protection, we will ensure
          that the information that is processed by us and our suppliers outside of the UK or EEA is protected in the
          same way as it would be if it was processed within the UK or the EEA. We ensure to use an appropriate data
          transfer mechanism, such as reliance on the protections set out in approved standard contractual clauses.</p>
        <p>Please contact us using the contact details above for further information on the specific mechanism used by
          us when transferring your information.</p>

        <h3>10. Security</h3>
        <p>We adopt robust technologies and policies to protect your information from being accidentally lost, used or
          accessed in an unauthorised way, altered or disclosed.</p>
        <p>We have implemented procedures to deal with any data breach and will notify you and any applicable regulator
          of a breach where we are legally required to do so.</p>
        <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will
          take steps to protect your information, we cannot guarantee the security of your information transmitted to
          the Sites; any transmission is at your own risk. Once we have received your information, we will use strict
          procedures and security features to try to prevent unauthorised access.</p>
        <p>When you open an account you may create a password, or other secure login method and also provide payment
          card details. You must use a unique password and keep any password you create, or other secure login method,
          secret in order to help prevent others from accessing your account.</p>

        <h3>11. Your Rights</h3>
        <p>Under certain circumstances, you have rights under data protection law in relation to the information we hold
          about you.</p>
        <p>These include:</p>
        <ul>
          <li>The <strong>right of access</strong>. This is also known as a “data subject access request”. You have the
            right to receive a copy of the information we hold about you and to check that we are lawfully processing
            it.
          </li>
          <li>The <strong>right to rectification</strong>. You are entitled to have any incomplete or inaccurate
            information we hold about you corrected, though we may need to verify the accuracy of the new information
            you provide to us.
          </li>
          <li>The <strong>right to erasure</strong>. This is also known as “the right to be forgotten” which enables you
            to request the deletion or removal of certain of the information that we hold about you where there is no
            good reason for us continuing to process it. This right is not absolute and only applies in certain
            circumstances.
          </li>
          <li>The <strong>right to restrict processing</strong>. You have the right to block or suppress further use of
            your information in certain circumstances. When processing is restricted, we may still have a lawful reason
            to store your information, but we will not use it further.
          </li>
          <li>The <strong>right to data portability</strong>. You have the right to receive your information in a
            structured, commonly used and machine-readable format which you can transfer to another service provider or
            other third party.This right is not absolute and only applies in certain circumstances.
          </li>
          <li>The <strong>right to withdraw consent</strong>. Where we rely on consent to use your information, you have
            the right to withdraw that consent at any time. Withdrawing consent will not, however, make unlawful our use
            of your information before you withdraw your consent. If you withdraw your consent, we may not be able to
            provide certain services to you.
          </li>
          <li>The <strong>right to object to processing</strong>. You have the right to object to certain types of
            processing of your information, including processing for direct marketing purposes and profiling. You can
            object by changing your marketing preferences and disabling cookies as set out in <strong>section 6.
              Cookies</strong> and <strong>section 7. Marketing</strong> or by contacting us.
          </li>
          <li>You have the <strong>right not to be subject to a decision based solely on automated processing</strong>
            of your information, such as in connection with our fraud checks.
          </li>
        </ul>
        <p>
          To exercise any of these rights, please contact our Data Protection Officer in writing at <a
            href="mailto:dpo@mealdealer.co.uk">dpo@mealdealer.co.uk</a>.</p>
        <p>If you are unhappy with how we have handled your information you can contact your local data protection
          authority. In the UK, this is the <a href="https://ico.org.uk">Information Commissioner’s Office</a>. We would, however, really appreciate the
          chance to deal with your concerns before you approach your local data protection authority and so we please
          ask that you contact us first.
        </p>

        <h3>12. Changes To Our Privacy Policy</h3>
        <p>We update this privacy policy from time to time, so remember to check back in every so often, in case anything has changed and, where appropriate, we may notify you of the changes, for example by email or push notification. </p>
        <p>This privacy policy was last updated: 6th January 2022</p>
        <p><strong>MealDealer Ltd</strong>, 71-75 Shelton Street, London, United Kingdom</p>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  components: {},
  data() {
    return {}
  },
  mounted() {
    document.title = `MealDealer - The Largest Food & Groceries Offer In Town - Privacy Policy`
  },
}
</script>

<style scoped>
h3, h1 {
  margin-left: 20px
}
ol {
  list-style-type: decimal;
  margin-left: 30px
}

ul {
  list-style-type: disc;
  margin-left: 30px
}

.content {
  background: #ffffff;
  padding: 30px;
  margin: 30px
}

.content p {
  margin: 20px;
}
</style>