<template>
  <main id="root">
    <mainPageHeader></mainPageHeader>
    <section id="m-app" class="m-app">
      <div class="m-app__icerik">
        <h4>Download our app & get rewarded</h4>
        <div class="percent">
          <div class="percent__base">
            <div class="percent__percent"></div>
          </div>
          <div class="percent__percent-yazi">76%</div>
        </div>
        <div class="m-app__pre-register pre-register" reduce-motion>
          <h5>pre-register for the app</h5>
          <div
            class="pre-register__eleman"
            v-for="(registerElement,idx) in registerFormLabels"
            :key="idx"
          >
            <div class="checkbox">
              <input
                type="checkbox"
                :name="registerElement.name"
                :id="registerElement.name"
                v-model="registerElement.checked"
              >
              <label
                :for="registerElement.name"
                class="checkbox__label"
              >
                <div class="checkbox__label-kutu"></div>
                <span class="checkbox__label-yazi">
									<h6>{{ registerElement.title }}</h6>
								</span>
              </label>
            </div>
          </div>
          <button class="pre-register__buton buton buton--2">
            <span>Your e-mail or phone number</span>
            <img src="@/assets/img/right-arrow.svg" alt="Right Arrow Icon">
          </button>
        </div>
        <div class="m-app__download-icons">
          <a href="#" class="m-app__download-icon download-buton buton buton--hayalet">
            <img src="@/assets/img/google-play.svg" alt="Download from google play buton">
          </a>
          <a href="#" class="m-app__download-icon download-buton buton buton--hayalet">
            <img src="@/assets/img/app-sotre.svg" alt="Download from app store buton">
          </a>
        </div>
      </div>
      <img class="m-app__background-asset" src="@/assets/img/mobile-app-background.png"
           alt="Meat Dealer Mobile App background image">
    </section>
  </main>
</template>

<script>
import mainPageHeader from '@/components/headers/main-page-header'
export default {
  name: "Home",
  components: {
    mainPageHeader
  },
  data() {
    return {
      labelsField: [],
      registerFormLabels: [
        {
          name: 'pre-register-1',
          id: 'pre-register-1',
          title: 'Win free meals or groceries',
          checked: true,
        },
        {
          name: 'pre-register-2',
          id: 'pre-register-2',
          title: 'Get app-only coupon alerts',
          checked: true,
        },
        {
          name: 'pre-register-3',
          id: 'pre-register-3',
          title: 'Join our in-app loyalty program',
          checked: true,
        },
      ]
    }
  },
  mounted() {
    document.title = `MealDealer - The Largest Food & Groceries Offer In Town`
  },
}
</script>

<style scoped>

</style>